import { map, Observable } from 'rxjs';
import { CompanyModel } from '../services/u2api.services';
import { GetUserResponseDto } from '../services/usersapi/usersapi.services';
import { IamWrapperConstants } from '../iamWrapperConstants';
import { TransformUtilities } from './utilities.transform';

export class GetUserAccessedCompaniesTransforms {
  static transformOutput(response$: Observable<GetUserResponseDto>): Observable<CompanyModel[]> {
    return response$.pipe(
      map((userData: GetUserResponseDto) =>
        TransformUtilities.ExtractUserAccessedCompanies(userData)?.slice(0, IamWrapperConstants.CompaniesPageSize),
      ),
    );
  }
}
