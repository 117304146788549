import { map, Observable } from 'rxjs';
import { CompanyModel, UserRoleCompany } from '../services/u2api.services';
import { AuditUserCommand } from '../services/usersapi/usersapi.services';
import { CompanyDto } from '../services/rolesapi/rolesapi.services';
import { IamWrapperConstants } from '../iamWrapperConstants';

export class AddLatestAccessedCompanyTransform {
  static transformOutput(response$: Observable<void>, userCompanies: CompanyDto[]): Observable<CompanyModel[]> {
    return response$.pipe(
      map(response =>
        userCompanies.slice(0, IamWrapperConstants.CompaniesPageSize).map(
          company =>
            new CompanyModel({
              companyId: company.companyId,
              companyName: company.companyName,
            }),
        ),
      ),
    );
  }
  static transformInput(userRoleCompany: UserRoleCompany, userObjectId: string, userCompanies: CompanyDto[]): AuditUserCommand {
    const companyCode = userCompanies.find(company => company.companyId === userRoleCompany.companyId).companyName;
    return new AuditUserCommand({
      userObjectId,
      companyCode,
    });
  }
}
