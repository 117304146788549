export const environment = {
    envName: '#{PortalEnvironmentName}#',
    production: '#{U2PortalIsProduction}#',
    apiUrl: '#{U2PortalU2APIUrl}#',
    apiHost: '#{U2PortalU2APIHost}#',
    tenant: '#{U2PortalADTenant}#',
    clientId: '#{U2PortalClientId}#',
    crmCloudModelAppId: '#{U2PortalCRMCloudModelAppId}#',
    crmCloudURLParameter1: '#{U2PortalCRMCloudURLParameter1}#',
    crmCloudURLParameter2: '#{U2PortalCRMCloudURLParameter2}#',
    redirectUri: '#{U2PortalRedirectUri}#',
    appIdentifier:'#{U2PortalAppIdentifier}#',
    apiScope: '#{U2PortalApiScope}#',
    authority: '#{U2PortalAuthority}#',
    endPoints: {
    },
    navigateToLoginRequestUrl: '#{U2PortalNavigateToLoginRequestUrl}#',
    cacheLocation: '#{U2PortalCacheLocation}#',
    appInsights: {
      instrumentationKey: '#{U2PortalInstrumentationKey}#',
    },
    notificationApiHost: '#{U2PortalNotificationApiHost}#',
    billingApiHost: '#{U2PortalBillingApiHost}#',
    payrollApiHost: '#{U2PortalPayrollApiHost}#',
    peopleApiHost: '#{U2PortalPeopleApiHost}#',
    rolesApiHost: '#{U2PortalRolesApiHost}#',
    usersApiHost: '#{U2PortalUsersApiHost}#',
    authorizationApiHost: '#{U2PortalAuthorizationApiHost}#',
    configurationApiHost: '#{U2PortalConfigurationApiHost}#',
    falloutApiHost: '#{U2PortalFalloutApiHost}#',
    noteApiHost: '#{U2PortalNoteApiHost}#',
    utexApiHost: '#{U2PortalUtexApiHost}#',
    V4PortalUrl: '#{V4PortalBaseUrl}#',
    intframeworkApiHost: '#{U2PortalIntframeworkApiHost}#',
    crmCloudDomain: '#{U2PortalCRMCloudDomain}#',
    renderingProviderNumberNotRequired: '#{U2PortalRenderingProviderNumberNotRequired}#',
    compatibility: {
      useIamApis: '#{U2PortalUseIamApis}#',
    },
    logging: {
      enableTrace:'#{U2PortalEnableTrace}#'
    }
  };
