import { map, Observable } from 'rxjs';
import { ScreenRole, Screens, ScreenUser } from '../services/u2api.services';
import { GetUserResponseDto, ResourceDto, UserRoleResponseDto } from '../services/usersapi/usersapi.services';
import { IamWrapperConstants } from '../iamWrapperConstants';

export class GetUserPermissionsTransforms {
  static transformOutput(observableResponse: Observable<GetUserResponseDto>): Observable<ScreenUser> {
    const base10 = 10;
    return observableResponse.pipe(
      map((usersDto: GetUserResponseDto) => {
        const screenUser = new ScreenUser({
          userId: parseInt(usersDto.personId, base10),
          userName: [usersDto.firstName, usersDto.middleName, usersDto.lastName].join(' '),
          objectId: usersDto.objectId,
          roles: GetUserPermissionsTransforms.mapPermissionsToScreenRoles(usersDto.roles, usersDto.resources),
        });
        screenUser[IamWrapperConstants.UserCompaniesKey] = usersDto.companies;
        return screenUser;
      }),
    );
  }

  static mapPermissionsToScreenRoles(roles: UserRoleResponseDto[], resources: ResourceDto[]): ScreenRole[] {
    if (roles === undefined || roles.length === 0) {
      return undefined;
    }
    if (resources === undefined || resources.length === 0) {
      return undefined;
    }

    // create a single role with all unique screens in it
    // roles are not consumed by the caller and thus can be safely ignored here.
    // canImpersonate is reduced to a single value for the role
    const screenRole: ScreenRole = new ScreenRole({
      roleId: roles[0].roleId,
      roleName: roles[0].roleName,
      canImpersonate: roles.some(role => role.canImpersonate),
    });

    screenRole.screens = resources.map(
      (resource: ResourceDto) =>
        new Screens({
          screenId: resource.resourceId,
          parentId: resource.parentId,
          displayOrder: resource.displayOrder,
          screenName: resource.resourceName,
          screenCode: resource.code,
          path: resource.path,
          cssClass: resource.cssClass,
          display: resource.display,
          isAuthorized: resource.isAuthorized,
          permissions: resource.permissions,
        }),
    );

    return [screenRole];
  }
}
