import { IamWrapperConstants } from '../iamWrapperConstants';
import {
  CompanyDto,
  ResourcesResponseDto,
  RolePermissionsReqDto,
  RolePermissionsResponseDto,
} from '../services/rolesapi/rolesapi.services';
import { CompaniesModel, CompanyModel, ScreensModel } from '../services/u2api.services';
import { GetUserResponseDto } from '../services/usersapi/usersapi.services';

export class TransformUtilities {
  static extractCompanyNames(companies?: CompaniesModel[] | undefined): string[] {
    const selectedCompanies = companies?.filter(company => company['IsSelected'] === true);
    const companyNames = selectedCompanies?.map(company => company['CompanyName']) ?? [];
    return companyNames;
  }

  static mapResourcePermissionsToScreens(
    permissions?: RolePermissionsResponseDto[],
    allResources?: ResourcesResponseDto[],
    applicationName?: string | undefined,
  ): ScreensModel[] | null {
    // Only pick the screens that belong to the role's application
    const screens: Record<string, ScreensModel> = TransformUtilities.getApplicationScreens(allResources, applicationName);

    if (permissions === null || permissions === undefined) {
      return Object.values(screens);
    }
    const permissionsByResource: Record<string, string[]> = {};
    permissions.forEach(permission => {
      const { resource, permission: permissionType } = permission;
      if (permissionsByResource[resource]) {
        permissionsByResource[resource].push(permissionType);
      } else {
        permissionsByResource[resource] = [permissionType];
      }
    });

    const roleScreens = Object.entries(permissionsByResource).map(([screenName, permissions]) => {
      const isEdit = permissions.includes('Update');
      const isCreate = permissions.includes('Create');
      const isRead = permissions.includes('Read');
      const isDelete = permissions.includes('Delete');
      const isApprove = permissions.includes('Approve');

      return new ScreensModel({
        screenId: 0, // Replace with the appropriate screenId
        screenName,
        isEdit,
        isDelete,
        isRead,
        isCreate,
        isApprove,
      });
    });

    for (const roleScreen of roleScreens) {
      const screenId = screens[roleScreen.screenName]?.screenId;
      screens[roleScreen.screenName] = roleScreen;
      screens[roleScreen.screenName].screenId = screenId;
    }

    // return only the values of the screens object
    return Object.values(screens);
  }

  static mapCompanyData(companyNames?: string[] | undefined, allCompanies?: CompanyDto[]): CompanyModel[] | null {
    if (companyNames === null || companyNames === undefined) {
      return null;
    }

    return companyNames.map(companyName => {
      const companyModel = new CompanyModel();
      companyModel.companyName = companyName;
      companyModel.companyId = allCompanies?.find(company => company.companyName === companyName)?.companyId;
      companyModel['isSelected'] = true;
      return companyModel;
    });
  }

  static hasAllCompanies(companyNames: string[] | undefined, allCompanies?: CompanyDto[] | undefined): boolean {
    if (companyNames === null || companyNames === undefined || allCompanies === null || allCompanies === undefined) {
      return false;
    }

    return allCompanies.every(company => companyNames.some(companyName => company.companyName.toLowerCase() === companyName.toLowerCase()));
  }

  static mapScreensToResourcePermissions(screens: ScreensModel[]): RolePermissionsReqDto[] {
    const rolePermissions = new Array<RolePermissionsReqDto>();
    screens.forEach(screen => {
      rolePermissions.push(...TransformUtilities.mapScreenToPermissions(screen));
    });
    return rolePermissions;
  }

  private static getApplicationScreens(allResources: ResourcesResponseDto[], applicationName: string): Record<string, ScreensModel> {
    const screens: Record<string, ScreensModel> = {};
    allResources?.forEach(resource => {
      const resourceName = resource.name;
      if (
        applicationName === null ||
        applicationName === undefined ||
        applicationName?.toLowerCase() === resource?.applicationName?.toLowerCase()
      ) {
        screens[resourceName] = new ScreensModel({
          screenId: resource.resourceId,
          screenName: resourceName,
          isEdit: false,
          isDelete: false,
          isRead: false,
          isCreate: false,
          isApprove: false,
        });
      }
    });
    return screens;
  }

  private static mapScreenToPermissions(screen: ScreensModel): RolePermissionsReqDto[] {
    const rolePermissions: RolePermissionsReqDto[] = [];
    const screenName = screen['ScreenName'];

    // for loop allows screen permissions to be added as long as they begin with 'is'
    for (const [key, value] of Object.entries(screen)) {
      if (key.toLowerCase().startsWith(IamWrapperConstants.PermissionPrefix) && value === true) {
        // remove the permission prefix from the key to get the permission
        const permission = key.substring(IamWrapperConstants.PermissionPrefix.length);
        rolePermissions.push(new RolePermissionsReqDto({ permission, resource: screenName }));
      }
    }
    return rolePermissions;
  }

  static ExtractUserAccessedCompanies(userData: GetUserResponseDto): any {
    let companies: CompanyModel[] = userData.companies?.map(
      company =>
        new CompanyModel({
          companyId: company.companyId,
          companyName: company.companyName,
        }),
    );

    const roleCompanies = userData.roles.map(role => role.roleCompanies).flat();

    // if no user company is found, try to set a default company as configured
    if (companies === undefined || companies === null || companies.length === 0) {
      const defaultCompany = roleCompanies.find(roleCompany => roleCompany.companyName === IamWrapperConstants.DefaultCompanyName);
      companies = [new CompanyModel({ companyId: defaultCompany.companyId, companyName: defaultCompany.companyName })];
    }

    // append the rest of distinct companies
    roleCompanies.forEach(roleCompany => {
      if (!companies.some(company => company.companyId === roleCompany.companyId)) {
        companies.push(
          new CompanyModel({
            companyId: roleCompany.companyId,
            companyName: roleCompany.companyName,
          }),
        );
      }
    });

    return companies;
  }
}
